import "./navbar.scss";
import { Link } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";


const Navbar = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  return (
    <>
      <section className="main-navbar">
        <div className="container-fluid">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="/"><img src="\logo.svg" alt="img" className="img-fluid" /></a>
            <div className="twice-btn">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class=""><img src="\assets\bar.svg" alt="img" className="img-fluid" /></span>
              </button>
            </div>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <HashLink to="#" class="nav-link" aria-current="page"
                    style={
                      isActive("")
                        ? {
                          color: "#FFF",
                          fontWeight: "600"
                        }
                        : {}
                    }
                  >Home</HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="#feature" class="nav-link" 
                   style={
                    isActive("#feature")
                      ? {
                        color: "#FFF",
                        fontWeight: "600"
                      }
                      : {}
                  }
                  >Services</HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="#about" class="nav-link" 
                   style={
                    isActive("#about")
                      ? {
                        color: "#FFF",
                          fontWeight: "600"
                      }
                      : {}
                  }
                  >Contact</HashLink>
                </li>
                {/* <li class="nav-item">
                  <HashLink to="#faqs" class="nav-link" 
                  style={
                    isActive("#faqs")
                      ? {
                        color: "#FFF",
                          fontWeight: "600"
                      }
                      : {}
                  }
                  >FAQs</HashLink>
                </li> */}
              </ul>
              <div className="right-side">
                <a target='_blank' href="mailto: raynasser123@gmail.com" className="launch-btn">Contact Us</a>
              </div>
            </div>
          </nav>
        </div>
      </section>


      {/* <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        </Offcanvas.Body>
      </Offcanvas> */}
    </>
  );
};

export default Navbar;
