// import logo from './logo.svg';
import '../../App.scss';
import React, { useState, useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';
import Partners from './Partners/Partners';
import Twice from './TwiceSection/Twice';
import Feature from './Feature/Feature';
import DataOwner from './DataOwnership/DataOwner';
import Faqs from './Faqs/Faqs';
import LastSection from './LastSection/LastSection';
import Choose from './Choose/Choose';




function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])




  return (
    <>
      <Navbar />
      <Banner />
      <Partners />
     
      {/* <Feature /> */}
      {/* <DataOwner /> */}
      {/* <Faqs /> */}
      <Choose />
      <Twice />
      <LastSection />
    </>
  );
}

export default Landing;