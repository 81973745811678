import React from 'react'
import './choose.scss'

const Choose = () => {
    return (
        <>
        <section className="mainchoose">
            <div className="custom-container">
                <h6 className="upperheading">
                Why Choose Digital Tech Group?
                </h6>
                <div className="row">
                    <div className="col-lg-6 col-sm-12 m-auto">
                        <div className="choosemainimg">
                            <img src="\assets\chooseimg (1).png" alt="innerchooseimg" className="innerchooseimg img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 m-auto">
                        <div className="rightisdecontent">
                            <div className="rightinnercontent">
                                <img src="\assets\imgone.svg" alt="rightimg" className="rightimg" />
                                <div className="rightleftcontent">
                                    <h6 className="mainhead">Innovation</h6>
                                    <p className="mainpara">Embrace the future with us as we continuously explore and adopt the latest technologies, staying ahead in the rapidly evolving financial landscape.</p>
                                </div>
                            </div>
                            <div className="rightinnercontent">
                                <img src="\assets\img2.svg" alt="rightimg" className="rightimg" />
                                <div className="rightleftcontent">
                                    <h6 className="mainhead">Security</h6>
                                    <p className="mainpara">Your financial security is our utmost priority. Rest assured that your data and transactions are protected by industry-leading security measures.</p>
                                </div>
                            </div>
                            <div className="rightinnercontent">
                                <img src="\assets\img3.svg" alt="rightimg" className="rightimg" />
                                <div className="rightleftcontent">
                                    <h6 className="mainhead">Expertise</h6>
                                    <p className="mainpara">Benefit from the wisdom of seasoned financial professionals who possess a deep understanding of the market and are committed to your success.</p>
                                </div>
                            </div>
                            <div className="rightinnercontent">
                                <img src="\assets\img4.svg" alt="rightimg" className="rightimg" />
                                <div className="rightleftcontent">
                                    <h6 className="mainhead">Customer-Centric Approach</h6>
                                    <p className="mainpara">We place our customers at the heart of everything we do. Expect a seamless and personalized experience that exceeds your expectations.</p>
                                </div>
                            </div>
                            <div className="rightinnercontent">
                                <img src="\assets\img5.svg" alt="rightimg" className="rightimg" />
                                <div className="rightleftcontent">
                                    <h6 className="mainhead">Transparency</h6>
                                    <p className="mainpara">Open and honest communication is the foundation of our relationship with our clients. We keep you informed at every step, ensuring complete transparency in all our dealings.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="mainchoose">
        <div className="custom-container">
            <div className="row" style={{marginBottom: "40px"}}>
                <div className="col-lg-6 col-sm-12 m-auto">
                    <div className="leftheading">
                        <h4 className="leftinnerheading">Join Digital Tech Group</h4>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 m-auto">
                    <div className="rightheading">
                        <p className="rightheadinginner">Join us at Digital Tech Group and embark on a journey towards financial prosperity. Whether you're a business seeking streamlined payments or an individual looking for smart investment opportunities, we have the solutions you need.</p>
                    </div>
                </div>
            </div>
            <img src="\assets\Group 1.png" alt="mainmapimg" className="mainmapimg img-fluid" />
        </div>
    </section>
    </>
    )
}

export default Choose