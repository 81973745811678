import React from 'react'
import "./lastsection.scss"

const LastSection = () => {
    return (
        <>
            <section className="lastsection" id='about'>
                <div className="custom-container">
                    <div className="logo-set">
                        <img src="\logo.svg" alt="img" className='img-fluid' />
                        <h6>Digital Tech Group LLC
                            175 East 62nd St Apt 9D
                            New York NY 10065</h6>
                    </div>
                    <div className="last-text">
                        <p>Copyright 2023   |   ©  All rights reserved. </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LastSection
