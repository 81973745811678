import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const ForPassword = ({ setSuperPassword }) => {
  const history = useHistory();
  const password = "satoshi";
  const [passCheck, setCheckPass] = useState("");
  const [error, setError] = useState(false);
  const getpass = localStorage.getItem("pass");
  const [showPassword, setShowPassword] = useState(false);

  const validatePass = () => {
    let validation = false;
    if (passCheck == password) {
      validation = true;
    } else {
      validation = false;
    }
    setError(!validation);
    return validation;
  };
  const handleEnter = () => {
    if (validatePass()) {
      setSuperPassword(password);
      localStorage.setItem("pass", password);
      history.push("/landing");
      // window.location.reload();
    }
  };
  useEffect(() => {
    if (getpass == password) {
      history.push("/landing");
    }
  }, []);

  return (
    <>
      <div className="wrapper-pass">
        <section className="forpassword">
          <img src="\logo.svg" alt="img" className="img-fluid" />
          <div className="option-field">
            <h6>Please enter your password below to log in.</h6>
            {/* <div className="parent-for">
            <img src="\mail.svg" alt="img" className="img-fluid mail-icon" />
          <input
            type="text"
            placeholder="Email address"
            className="mb-3"
            
          />
          </div> */}
            <div className="parent-for">
              <img src="\key.svg" alt="img" className="img-fluid mail-icon" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={passCheck}
                onChange={(e) => {
                  setCheckPass(e.target.value);
                  setError(false);
                }}
              />
              <img
                src="\aye.svg"
                alt="img"
                className="img-fluid pass-icon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {error ? <span>Wrong Password*</span> : ""}
          </div>
          <button onClick={handleEnter}>Enter</button>
        </section>
      </div>
    </>
  );
};

export default ForPassword;
