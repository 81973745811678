
import React from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <section className="main-banner">
      <img src="\newassets\banner-bg.png" alt="img" className="img-fluid banner-bg" />
        <div className="custom-container">
          <div className="inner-main-text">
            <div className="header-btn">
              <p className="dataowner-btn">
              Welcome to Digital Tech Group</p>
            </div>
            <h2>Your Gateway to <span>Seamless</span> Payments and <span>Smart</span> Investments</h2>
            <p>At Digital Tech Group, we bring you a world of financial opportunities through cutting-edge technology and expert guidance. As a leading payments and investments company, we strive to empower individuals and businesses alike to thrive in today's dynamic financial landscape.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
