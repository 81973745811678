import React from 'react'
import "./twice.scss"

const Twice = () => {
    return (
        <>
            <section className="ready-to-take">
                <img src="\newassets\ready-to-take-bg.png" alt="img" className='img-fluid ready-bg' />
                <div className="custom-container">
                <div className="inner-text">
                <h4>Ready to take the next step?</h4>
                  <p>Contact us today to explore how our payments and investments expertise can transform your financial future. Together, let's unlock a world of possibilities.</p>
                  <a href='mailto: raynasser123@gmail.com'>Contact Us</a>
                </div>
                </div>
            </section>
        </>
    )
}

export default Twice
