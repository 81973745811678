import React, { useState } from 'react'
import './partners.scss'
import Modal from 'react-bootstrap/Modal';



const Partners = () => {
   

    return (
        <>
            <section className="our-section">
                <div className="custom-container">
                    <div className="main-heading">
                  <h4>  Our Services</h4>
                    </div>
                    <div className="bottom-content">
                        <div className="inner-card">
                            <img src="\newassets\secure.svg" alt="img" className='img-fluid' />
                            <h6>Secure and Convenient Payments</h6>
                            <p>Streamline your payment processes with our state-of-the-art payment solutions. Whether you need to process transactions online, in-store, or across borders, our secure and seamless payment systems ensure that your funds are transferred swiftly and securely.</p>
                        </div>
                        <div className="inner-card">
                            <img src="\newassets\intelligent.svg" alt="img" className='img-fluid' />
                            <h6>Intelligent Investments</h6>
                            <p>Discover a range of intelligent investment options tailored to meet your unique financial goals. Our team of seasoned financial experts leverages data-driven insights to guide you towards smart investment choices, helping you maximize returns while managing risks.</p>
                        </div>
                        <div className="inner-card">
                            <img src="\newassets\personlized.svg" alt="img" className='img-fluid' />
                            <h6>Personalized Financial Advice</h6>
                            <p>We understand that everyone's financial journey is unique. That's why our dedicated advisors take the time to understand your aspirations and tailor strategies that align with your specific needs and risk tolerance, ensuring a brighter financial future.</p>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Partners